import React, { FC, FormEvent, RefObject, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import './contact.css'
import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import Form from '../components/Form/FormView';
import { getImageByRelativePath } from '../shared/utilities';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { navigate } from "gatsby"

const ContactPage: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'contactPage' });
    const [loadingContact, setLoadingContact] = useState<boolean>(false);
    const [loadingBooking, setLoadingBooking] = useState<boolean>(false);
    const [statusContact, setStatusContact] = useState<'success' | 'error'>();
    const [statusBooking, setStatusBooking] = useState<'success' | 'error'>();

    const { width } = useWindowDimensions();
    function getBanner() {
        var banner
        if (width > 768) {
            banner = <StaticImage
                className={'banner'}
                src='../images/banner8.jpg'
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />

        }
        else {
            banner = <StaticImage
                className={'banner'}
                src='../images/banner8_mobile.jpg'
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        return banner
    }
    const banner = getBanner();

    const contactRef: any = useRef(null);
    const boekenRef: any = useRef(null);
    const executeScroll = (myRef: RefObject<HTMLDivElement>) => myRef.current && myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })


    const sendMail = async (e: FormEvent<HTMLFormElement>, type: 'booking' | 'contact') => {
        e.preventDefault();

        const formData = new FormData(e.target as HTMLFormElement);
        const body = {
            selectedTrip: formData.get('selectedTrip'),
            name: formData.get('name'),
            email: formData.get('email'),
            phone: formData.get('phone'),
            from: formData.get('from'),
            until: formData.get('until'),
            message: formData.get('message'),

            submitted: new Date().toLocaleTimeString("nl-NL", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            type
        };

        if (type === 'booking') {
            setLoadingBooking(true);
        } else {
            setLoadingContact(true);
        }

        const response = await fetch('/api/v1/contact', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        const data = await response.json();
        
        if (data.success) {
            type === 'booking' ? setStatusBooking('success') : setStatusContact('success');
            navigate("/aanvraag/");
        } else {
            type === 'booking' ? setStatusBooking('error') : setStatusContact('error');
        }

        setLoadingBooking(false);
        setLoadingContact(false);
    }

    const data: any = useStaticQuery(graphql`
    {
        Test: file(relativePath: {eq: "stock4.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
       Test2: file(relativePath: {eq: "stock3.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 900)
        }
        relativePath
      }
     }
    `);

    return (
        <Layout>
            <div>
                <Seo title={"Contact"}  description={"Jouw droomreis laten samenstellen? Onze reisexperts staan voor je klaar!"}/>
                <div style={{
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%',
                }}>
                    {banner}

                    <div className='row contact_btn_row'>
                        <button onClick={() => executeScroll(boekenRef)} className='read_more_btn contact_btn'>{t('boekenButton')}</button>
                        <button onClick={() => executeScroll(contactRef)} className='read_more_btn contact_btn'>{t('contactButton')}</button>
                    </div>
                </div>
            </div>

            <div ref={boekenRef}>
                <Form image_left={true} image={getImageByRelativePath('stock3.jpg', data)} onSubmit={(e) => sendMail(e, 'booking')} loading={loadingBooking} status={statusBooking} />
            </div>
            <div style={{ height: '80px' }}></div>

            <div ref={contactRef}>
                <Form image_left={false} image={getImageByRelativePath('stock4.jpg', data)} onSubmit={(e) => sendMail(e, 'contact')} loading={loadingContact} status={statusContact} />
            </div>
        </Layout>
    )
}

export default ContactPage;